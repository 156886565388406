<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" dark color="primary" dense flat slot="header">
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <v-text-field
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('name')"
                v-model="record.name">
              <template #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
            </v-text-field>
            <v-select
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('field_type')"
                v-model="record.field_type"
                validate-on-blur
                :items="$lodash.sortBy(field_types, 'text')">
              <template #label>{{ $store.getters.translate('field_type') }}<span class="red--text"><strong>*</strong></span></template>
            </v-select>
            <v-combobox
                v-if="record.field_type === 'select_single' || record.field_type === 'select_multiple'"
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('list_options')"
                chips
                deletable-chips
                small-chips
                clearable
                multiple
                v-model="record.available_options"/>
            <v-switch
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('mandatory')"
                v-model="record.mandatory"/>
            <v-switch
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('show_in_search')"
                v-model="record.show_in_search"/>
            <v-switch
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('show_on_creation')"
                v-model="record.show_on_creation"/>
            <v-switch
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('show_on_update')"
                v-model="record.show_on_update"/>
            <v-switch
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('show_in_portal')"
                v-model="record.show_in_portal"/>
            <v-switch
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('use_in_import')"
                v-model="record.use_in_import"/>
          </v-card-text>
        </div>
        <v-footer-panel ref="footer_panel" :model="page.name"/>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  props: ["modal_name"],
  components: {BaseModal, BaseDraggableModal},
  data() {
    return {
      page: {
        title: this.$store.getters.translate("fields"),
        name: "fields",
        model: "field",
      },
      record: {
        name: null,
        field_type: null,
        model_type: null,
        custom_field: 1,
        active: 1,
        mandatory: false,
        show_in_search: true,
        show_on_creation: true,
        show_on_update: true,
        show_in_portal: false,
      },
      loading: false,
      field_types: [],
    };
  },
  created() {
    this.field_types = helpFunctions.field_types;
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(id) {
      this.openModal(this.modal_name);
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
        .then((response) => {
          this.record = response.data;
          this.$refs.footer_panel.record = this.record;
          this.$refs.footer_panel.load();
          this.loading = false;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    save() {
      if(!this.loading) {
        let usual_fields_required = [];
        usual_fields_required['name'] = true;
        usual_fields_required['field_type'] = true;
        let error = false;
        for (let key in usual_fields_required) {
          if (usual_fields_required[key] && (!this.record[key] || this.record[key] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(key));
            error = true;
          }
        }
        if (!error) {
          this.loading = true;
          this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.$emit("refresh");
                  this.reset();
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    reset() {
      this.record = {
        name: null,
        field_type: null,
        model_type: null,
        custom_field: 1,
        active: 1,
        mandatory: false,
        show_in_search: true,
        show_on_creation: true,
        show_on_update: true,
        show_in_portal: false,
      };
      this.closeModal(this.modal_name);
    },
  },
};
</script>